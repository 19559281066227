body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #070707;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 70px;
  padding: 70px 20px 0px 20px;
}

a,
p,
h1,
h2,
h3,
span,
input,
img,
select,
div,
button,
svg {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
