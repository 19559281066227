nav {
  width: 100%;

  background-color: #000000e5;

  color: #fff;
  position: fixed;
  top: 0;
  z-index: 22;
}

.menu_bar,
.menu_close {
  display: none;
}

.menu_close .menu,
.close {
  width: 30px;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.nav__bar-wrapper {
  margin: 0 auto;
  max-width: 1140px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav__bar-logo a {
  font-size: 25px;
  font-weight: 600;
  cursor: default;
  text-decoration: none;
  color: #fff;
}
/* 
.nav__bar-items {
  width: 0px;
} */

.nav__bar-items a {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  margin: 0 30px;
  padding: 5px 5px 5px 5px;
  border-radius: 15px;
  -webkit-tap-highlight-color: transparent;
}

.nav__bar-items a:hover {
  /* text-decoration: underline; */
  background-color: white;
  color: black;
}

.nav__bar-items .active {
  /* text-decoration: underline; */
  background-color: white;
  color: black;
}

.hide_menu,
.hide_close {
  display: none;
}

.close_menu a {
  display: none;
}

@media only screen and (max-width: 760px) {
  .nav__bar-items a {
    margin: 0 25px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .nav__bar-items {
    display: none;
  }
  .menu_bar,
  .menu_close {
    display: block;
  }
  .menu_bar {
    position: fixed;
    top: 69.8px;
    width: 100%;
    height: 100%;
    background-color: #000000e5;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 0;
    transition: all 0.4s ease;
  }
  .menu_bar a {
    font-size: 25px;
    display: block;
    margin: 20px 0;
  }
  .close_menu {
    transform: translateY(-230px);
  }

  .close_menu a {
    display: none;
  }
  .nav__bar-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
